import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { ColorAPI } from "../../apis/ColorAPI";
import { ColorPicker, useColor } from "react-color-palette";
import convert from "color-convert";

const EditColor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [color, setColorHex] = useColor("#123123");
  const [colors, setColor, TColor] = useState({
    name: "",
    value: "",
  });

  const [loading, setLoading] = useState(true);
  function convertColor(hex) {
    const rgb = convert.hex.rgb(hex);
    const hsv = convert.hex.hsv(hex);

    const colorData = {
      hex: hex,
      rgb: { r: rgb[0], g: rgb[1], b: rgb[2], a: 1 },
      hsv: { h: hsv[0], s: hsv[1], v: hsv[2], a: 1 },
    };
    return colorData;
  }

  const handleInputColor = (e) => {
    setColor({ ...colors, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    try {
      ColorAPI.get(id).then((color) => {
        setColor({
          name: color.name,
          value: color.value,
        });
        setColorHex(convertColor("#" + color.value));
        setLoading(false);
      });
    } catch (error) {}
  }, []);

  const apiCall = () => {
    colors.value = color.hex.substring(1);
    try {
      ColorAPI.update(colors, id).then(() => {
        navigate("/colors");
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      ) : (
        <section className="mt-20">
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Edit color
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  color name
                </label>
                <input
                  type="text"
                  value={colors.name}
                  name="name"
                  id="name"
                  onChange={handleInputColor}
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Color name"
                  required
                />
              </div>

              <div className="w-full sm:col-span-2">
                <ColorPicker
                  hideInput={["rgb", "hsv", "Saturation"]}
                  color={color}
                  onChange={setColorHex}
                  hideAlpha={true}
                />
              </div>
              <div className="text-center sm:col-span-2">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Edit color
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default EditColor;
