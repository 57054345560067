import React, { useEffect, useState } from "react";
import { AuthAPI } from "../../apis/AuthAPI";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo/logo_login_page.webp";
import ErrorAlert from "../../components/ErrorAlert";
import { Link } from "react-router-dom";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const handleChangeInputEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeInputPassword = (event) => {
    setPassword(event.target.value);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading === true) {
      handleLogin();
    }
  }, [loading]);

  const handleLogin = async () => {
    try {
      await AuthAPI.login(email, password).then((response) => {
        if (response.access_token != null) {
          dispatch(setToken(response.access_token));
          sessionStorage.setItem("token", response.access_token);
          navigate("/");
        }
      });
    } catch (error) {
      setShowError(true);
      setError({ name: [error.response.data.error] });
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/");
    }
    try {
      AuthAPI.csrfToken().then((response) => {});
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <section className="gradient-form h-screen bg-neutral-700">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      ) : (
        <div className=" h-full md:p-10 ">
          <div className="flex flex-wrap items-center justify-center">
            <div className="g-6 relative w-full md:w-1/2 lg:w-1/3">
              <ErrorAlert show={showError} error={error} />
            </div>
          </div>
          <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-200">
            <div className="w-full md:w-1/2 lg:w-1/3">
              <div className="block rounded-lg bg-neutral-800">
                <div className="g-0">
                  <div className="px-4 md:px-0">
                    <div className="md:mx-6 p-12">
                      <div className="text-center">
                        <img
                          className="mx-auto w-32 rounded-full"
                          src={logo}
                          alt="logo"
                        />
                        <h4 className="mb-12 mt-3 pb-1 text-xl font-semibold">
                          Administrator
                        </h4>
                      </div>
                      <form>
                        <div
                          className="relative mb-4"
                          data-te-input-wrapper-init
                        >
                          <input
                            type="text"
                            onChange={handleChangeInputEmail}
                            className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear placeholder:text-neutral-200 focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                            id="exampleFormControlInput1"
                            placeholder="Email"
                          />
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="peer-focus:text-primary peer-focus:text-primary pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-200 transition-all duration-200 ease-out -translate-y-[0.9rem] scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                          >
                            Email{" "}
                          </label>
                        </div>

                        <div
                          className="relative mb-4"
                          data-te-input-wrapper-init
                        >
                          <input
                            type="password"
                            onChange={handleChangeInputPassword}
                            className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear placeholder:text-neutral-200 focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                            id="exampleFormControlInput11"
                            placeholder="Password"
                          />
                          <label
                            htmlFor="exampleFormControlInput11"
                            className="peer-focus:text-primary dark:peer-focus:text-primary pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-200 transition-all duration-200 ease-out -translate-y-[0.9rem] scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                          >
                            Password{" "}
                          </label>
                        </div>

                        <div className="mb-12 pb-1 pt-1 text-center">
                          <button
                            onClick={() => setLoading(true)}
                            className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] bg-[#959493]"
                            type="button"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                          >
                            Log in
                          </button>
                        </div>
                        <Link to="/forgot_password">
                          <div className="relative mb-2 ml-4 right-0 opacity-50">
                            Forgot Password
                          </div>
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Login;
