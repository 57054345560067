import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { ShippingAPI } from "../../apis/ShippingAPI";
import ErrorAlert from "../../components/ErrorAlert";
const EditCity = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [cities, setCity] = useState({
    country: "",
    cost: "",
  });

  const [loading, setLoading] = useState(true);

  const handleInputCity = (e) => {
    setCity({ ...cities, [e.target.name]: e.target.value });
  };
  const handleInputValue = (e) => {
    if (/^(100(\.0+)?|([0-9]{1,2}(\.[0-9]+)?))$/.test(e.target.value)) {
      setCity({ ...cities, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    try {
      ShippingAPI.get(id).then((city) => {
        setCity({
          country: city.country,
          cost: city.cost,
        });
        setLoading(false);
      });
    } catch (error) {}
  }, []);

  const apiCall = async () => {
    try {
      await ShippingAPI.update(cities, id).then(() => {
        navigate("/countries");
      });
    } catch (error) {
      setShowError(true);
      setError(error.response.data.error);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      ) : (
        <section className="mt-20 relative">
          <ErrorAlert show={showError} error={error} />
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Edit country
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Country
                </label>
                <input
                  type="text"
                  value={cities.country}
                  name="country"
                  id="name"
                  onChange={handleInputCity}
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Country"
                  required
                />
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Cost
                </label>
                <input
                  type="number"
                  value={cities.cost}
                  name="cost"
                  id="value"
                  onChange={handleInputValue}
                  step="1"
                  inputMode="decimal"
                  pattern="[0-9]+([.][0-9]+)?"
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="cost"
                  required
                />
              </div>

              <div className="text-center">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Edit country
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default EditCity;
