// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// // import thunk from "redux-thunk";
// // import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./reducers";
// import { thunk } from "redux-thunk";
// const initialState = {}; // Définissez votre état initial ici

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   // composeWithDevTools(applyMiddleware(...middleware))
//   composeWithDevTools(applyMiddleware(thunk))
// );

// export default store;
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers";

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
