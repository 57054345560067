const StatsCard = ({ title, value, logo }) => {
  if (!isNaN(value)) {
    const num = parseFloat(value);
    if (num >= 1000) {
      const dividedNumber = (num / 1000).toFixed(2);
      value = dividedNumber.replace(/\.?0+$/, "") + "k";
    }
  }
  return (
    <div>
      <div className="flex flex-col md:flex-row mx-5 mb-2">
        <div className="flex flex-row bg-gradient-to-r from-purple-700 via-purple-800 to-purple-900 p-6 gap-8 rounded-lg border-2 border-purple-500 w-full">
          <div className="my-auto flex-grow ">
            <div className="text-lg text-purple-300">{title}</div>
            <div className="text-4xl text-purple-100">{value}</div>
          </div>
          <div className=" text-purple-300 my-auto bg-gradient-to-l from-purple-700 via-purple-800 to-purple-900 rounded-full p-4">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
            </svg> */}
            <img className="w-8 h-8" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
