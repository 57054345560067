import React, { useEffect, useState } from "react";
import StatsCard from "../components/StatsCard";
import PieChartReact from "../components/PieChartReact";
import VerticalBarChart from "../components/VerticalBarChart";
import { StatsAPI } from "../apis/StatsAPI";
import productShoesLogo from "../assets/logo/shoes-7-svgrepo-com.svg";
import orderLogo from "../assets/logo/product-svgrepo-com.svg";
import unpaidLogo from "../assets/logo/money-receive-svgrepo-com.svg";
import unpaidOrderLogo from "../assets/logo/shopping-basket-remove-svgrepo-com.svg";
const Home = () => {
  const [stats, setStats] = useState();
  const [loading, setLoading] = useState(true);
  const [yearsIncome, setYearsIncome] = useState([]);
  const [yearsOrders, setYearsOrders] = useState([]);
  const [selectedYearIncome, setSelectedYearIncome] = useState();
  const [selectedYearOrders, setSelectedYearOrders] = useState();

  const handleYearIncomeChange = (e) => {
    const year = e.target.value;
    setSelectedYearIncome(year);
  };

  const handleYearOrdersChange = (e) => {
    const year = e.target.value;
    setSelectedYearOrders(year);
  };

  useEffect(() => {
    try {
      StatsAPI.getAll().then((stats) => {
        setStats(stats);
        var yearsIncome = Object.keys(stats.income).sort((a, b) => b - a);
        var yearsOrders = Object.keys(stats.orders).sort((a, b) => b - a);
        setYearsIncome(yearsIncome);
        setYearsOrders(yearsOrders);
        setSelectedYearIncome(yearsIncome[0]);
        setSelectedYearOrders(yearsOrders[0]);
        setLoading(false);
        console.log(stats);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="flex mt-10 md:px-10 md:py-8 flex-col md:flex-row">
            <div className="w-full md:w-1/4 ">
              <StatsCard
                title={"Products"}
                value={stats.basicStats.total_products}
                logo={productShoesLogo}
              />
            </div>

            <div className="w-full md:w-1/4 ">
              <StatsCard
                title={"Orders"}
                value={stats.basicStats.total_orders}
                logo={orderLogo}
              />
            </div>

            <div className="w-full md:w-1/4 ">
              <StatsCard
                title={"Unpaid Orders"}
                value={stats.basicStats.unpaid_orders_count}
                logo={unpaidOrderLogo}
              />
            </div>

            <div className="w-full md:w-1/4 max-w-1/4">
              <StatsCard
                title={"Unpaid amount"}
                value={stats.basicStats.unpaid_orders_amount + "$"}
                logo={unpaidLogo}
              />
            </div>
          </div>

          <div className="flex mt-5 md:px-10 md:py-8 flex-col md:flex-row">
            <div className="w-full md:w-1/3 md:mr-2 relative bg-white mb-2 h-96">
              <div className="absolute top-0 right-0 mr-2">
                <select
                  onChange={handleYearIncomeChange}
                  className="w-full p-2.5  opacity-50 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {yearsIncome.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              {stats.income[selectedYearIncome] !== undefined ? (
                <VerticalBarChart
                  labels={stats.income[selectedYearIncome].labels}
                  value={stats.income[selectedYearIncome].values}
                  title={"Income"}
                />
              ) : (
                <VerticalBarChart />
              )}
            </div>

            <div className="w-full md:w-1/3 md:ml-2 md:mr-4 relative bg-white h-96 mb-2">
              <PieChartReact
                labels={stats.statusStats.labels}
                value={stats.statusStats.value}
              />
            </div>
            <div className="w-full md:w-1/3 relative bg-white mb-2 h-96">
              <div className="absolute top-0 right-0 mr-2">
                <select
                  onChange={handleYearOrdersChange}
                  className="w-full p-2.5  opacity-50 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {yearsOrders.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              {stats.orders[selectedYearOrders] !== undefined ? (
                <VerticalBarChart
                  labels={stats.orders[selectedYearOrders].labels}
                  value={stats.orders[selectedYearOrders].values}
                  title={"Orders"}
                />
              ) : (
                <VerticalBarChart />
              )}
            </div>
          </div>
        </div>
      )}
      ;
    </div>
  );
};

export default Home;
