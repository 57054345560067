import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const ProductAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `/api/admin/products?page=${page}`;

    if (
      params.categoryId !== undefined &&
      params.categoryId !== null &&
      params.categoryId !== "1"
    ) {
      url += `&category_id=${params.categoryId}`;
    }
    if (
      params.typeId !== undefined &&
      params.typeId !== null &&
      params.typeId !== "1"
    ) {
      url += `&type_id=${params.typeId}`;
    }
    if (
      params.collectionId !== undefined &&
      params.collectionId !== null &&
      params.collectionId !== "1"
    ) {
      url += `&collection_id=${params.collectionId}`;
    }
    if (params.search !== undefined && params.search !== null) {
      url += `&search=${params.search}`;
    }

    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (product, cancel = false) {
    const response = await api.request({
      url: `/api/admin/products`,
      method: "POST",
      data: product,
      // headers: { "Content-Type": "multipart/form-data" },
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/products/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  update: async function (product, id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/products/${id}`,
      method: "PUT",
      data: product,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/products/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(ProductAPI);
