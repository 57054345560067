import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { OrderStatusEnum } from "../utils/enum/OrderStatusEnum";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartReact = ({ labels, value }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Orders number",
        data: value,
        // backgroundColor: [
        //   "rgb(255, 99, 132)",
        //   "rgb(54, 162, 235)",
        //   "rgb(255, 205, 86)",
        //   "rgb(75, 192, 192)",
        //   "rgb(153, 102, 255)",
        //   "rgb(255, 159, 64)",
        // ],
        backgroundColor: [
          "rgb(255, 159, 64)",
          "rgb(255, 64, 64)",
          "rgb(75, 192, 192)",
          "rgb(64, 159, 255)",
          "rgb(255, 255, 64)",
          "rgb(255, 99, 132)",
          "rgb(64, 255, 255)",
          "rgb(255, 64, 255)",
          " rgb(159, 64, 255)",
          "rgb(255, 159, 255)",
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#fff",
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index] || "";
          if (label) {
            label += ": ";
          }
          label +=
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label;
        },
      },
    },
  };

  return (
    <div className="w-full h-full">
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChartReact;
