import React, { useEffect } from "react";
import { AuthAPI } from "../../apis/AuthAPI";
import { useDispatch } from "react-redux";
import { removeToken } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    try {
      AuthAPI.logout().then((response) => {
        dispatch(removeToken());
        sessionStorage.removeItem("token");
        navigate("/login");
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
};

export default Logout;
