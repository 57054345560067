import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { VariantAPI } from "../../apis/VariantAPI";
import imageNotFound from "../../assets/images/nope-not-here.webp";
import uploadIcon from "../../assets/logo/upload-svgrepo-com.svg";
import deleteIcon from "../../assets/logo/delete-svgrepo-com.svg";
import { API_URL_IMAGE_PRODUCT } from "../../apis/URL/constants";
const EditVariant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [variant, setVariant] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idImageDelete, setIdImageDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleInputType = (e) => {
    setVariant({ ...variant, [e.target.name]: e.target.value });
  };

  const handleChangeInputNumber = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]+([.][0-9]+)?$/.test(inputValue)) {
      setVariant({ ...variant, [e.target.name]: e.target.value });
    }
  };

  const [images, setImage] = useState([]);

  const openDeleteModal = (show, id) => {
    setShowModalDelete(show);
    setIdImageDelete(id);
  };

  const handleImageUpload = (event) => {
    console.log(event.target);
    const file = event.target.files[0];
    const updatedElements = [...images];
    if (event.target.name === "image1") {
      updatedElements[0] = {
        ...updatedElements[0],
        name: URL.createObjectURL(file),
      };
    } else if (event.target.name === "image2") {
      updatedElements[1] = {
        ...updatedElements[1],
        name: URL.createObjectURL(file),
      };
    } else if (event.target.name === "image3") {
      updatedElements[2] = {
        ...updatedElements[2],
        name: URL.createObjectURL(file),
      };
    } else if (event.target.name === "image4") {
      updatedElements[3] = {
        ...updatedElements[3],
        name: URL.createObjectURL(file),
      };
    }
    setImage(updatedElements);
    setVariant({ ...variant, [event.target.name]: file });
  };

  const getImage = (data) => {
    const imageArray = new Array(4).fill(null);

    data.images.forEach((image) => {
      imageArray[image.tag - 1] = {
        name: API_URL_IMAGE_PRODUCT + image.name,
        id: image.id,
      };
    });
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i] === null) {
        imageArray[i] = {
          name: imageNotFound,
        };
      }
    }
    setImage(imageArray);
  };

  const modifyImageById = (id, newName) => {
    const updatedElements = images.map((element) => {
      if (element.id === id) {
        return { ...element, name: newName };
      }

      return element;
    });

    setImage(updatedElements);
  };

  useEffect(() => {
    try {
      VariantAPI.get(id).then((variant) => {
        console.log(variant);
        const data = variant.variant;
        setVariant(data);
        getImage(data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error.response.data);
    }
  }, []);

  const deleteApiCall = () => {
    if (idImageDelete !== undefined) {
      try {
        VariantAPI.deleteImage(idImageDelete).then(() => {
          modifyImageById(idImageDelete, imageNotFound);
          setShowModalDelete(false);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const apiCall = () => {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("size", variant.size);
    formData.append("quantity", variant.quantity);
    formData.append("sku", variant.sku);
    formData.append("barcode", variant.barcode);
    if (variant.image1 != null) {
      formData.append("image1", variant.image1);
    }
    if (variant.image2 != null) {
      formData.append("image2", variant.image2);
    }
    if (variant.image3 != null) {
      formData.append("image3", variant.image3);
    }
    if (variant.image4 != null) {
      formData.append("image4", variant.image4);
    }
    try {
      VariantAPI.update(formData, id).then(() => {
        navigate("/items/" + variant.product_id);
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      ) : (
        <section className="mt-20">
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Edit variant
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="relative w-full lg:max-w-sm">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    SKU
                  </label>
                  <input
                    type="text"
                    value={variant.sku}
                    name="sku"
                    id="name"
                    onChange={handleInputType}
                    className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                    placeholder="SKU"
                    required
                  />
                </div>
              </div>
              <div className="relative w-full lg:max-w-sm">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    Barcode
                  </label>
                  <input
                    type="text"
                    value={variant.barcode}
                    name="barcode"
                    id="name"
                    onChange={handleInputType}
                    className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                    placeholder="Barcode"
                    required
                  />
                </div>
              </div>
              <div className="relative w-full lg:max-w-sm">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    Quantity
                  </label>
                  <input
                    type="number"
                    step="1"
                    value={variant.quantity}
                    name="quantity"
                    id="name"
                    onChange={handleChangeInputNumber}
                    className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                    placeholder="Quantity"
                    required
                  />
                </div>
              </div>

              <div className="relative w-full lg:max-w-sm">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    Size
                  </label>
                  <input
                    type="text"
                    value={variant.size}
                    name="size"
                    id="name"
                    onChange={handleInputType}
                    className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                    placeholder="Size"
                    required
                  />
                </div>
              </div>

              <div className="sm:col-span-2 flex justify-center mt-5">
                <div className="flex flex-wrap sm:flex-nowrap justify-center relative">
                  <div className="mt-2 mb-5 w-full sm:w-1/2 flex justify-center relative">
                    <img
                      className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                      alt="Image placeholder"
                      src={images[0].name}
                    />
                    {images[0].name === imageNotFound ? (
                      <div>
                        <label htmlFor="image1" className="cursor-pointer">
                          <img
                            src={uploadIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="Upload Icon"
                          />
                        </label>
                        <input
                          type="file"
                          id="image1"
                          className="hidden"
                          name="image1"
                          onChange={handleImageUpload}
                        />
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="image1" className="cursor-pointer">
                          <img
                            onClick={() => {
                              openDeleteModal(true, images[0].id);
                            }}
                            src={deleteIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="delete"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="mt-2 mb-5 w-full sm:w-1/2 flex justify-center relative">
                    <img
                      className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                      alt="Image placeholder"
                      src={images[1].name}
                    />
                    {images[1].name === imageNotFound ? (
                      <div>
                        <label htmlFor="image2" className="cursor-pointer">
                          <img
                            src={uploadIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="Upload Icon"
                          />
                        </label>
                        <input
                          type="file"
                          id="image2"
                          className="hidden"
                          name="image2"
                          onChange={handleImageUpload}
                        />
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="image2" className="cursor-pointer">
                          <img
                            onClick={() => {
                              openDeleteModal(true, images[1].id);
                            }}
                            src={deleteIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="delete"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="mt-2 mb-5 w-full sm:w-1/2 flex justify-center relative">
                    <img
                      className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                      alt="Image placeholder"
                      src={images[2].name}
                    />
                    {images[2].name === imageNotFound ? (
                      <div>
                        <label htmlFor="image3" className="cursor-pointer">
                          <img
                            src={uploadIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="Upload Icon"
                          />
                        </label>
                        <input
                          type="file"
                          id="image3"
                          className="hidden"
                          name="image3"
                          onChange={handleImageUpload}
                        />
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="image3" className="cursor-pointer">
                          <img
                            onClick={() => {
                              openDeleteModal(true, images[2].id);
                            }}
                            src={deleteIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="delete"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="mt-2 mb-5 w-full sm:w-1/2 flex justify-center relative">
                    <img
                      className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                      alt="Image placeholder"
                      src={images[3].name}
                    />
                    {images[3].name === imageNotFound ? (
                      <div>
                        <label htmlFor="image4" className="cursor-pointer">
                          <img
                            src={uploadIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="Upload Icon"
                          />
                        </label>
                        <input
                          type="file"
                          id="image4"
                          className="hidden"
                          name="image4"
                          onChange={handleImageUpload}
                        />
                      </div>
                    ) : (
                      <div>
                        <label htmlFor="image4" className="cursor-pointer">
                          <img
                            onClick={() => {
                              openDeleteModal(true, images[3].id);
                            }}
                            src={deleteIcon}
                            className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                            alt="delete"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2 text-center">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Edit variant
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {showModalDelete ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Warning</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModalDelete(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Are you certain that you wish to delete this image ? <br />
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalDelete(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={deleteApiCall}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default EditVariant;
