import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert";
import CartItem from "../../components/CartItem";
import { OrderAPI } from "../../apis/OrderAPI";
import { VariantAPI } from "../../apis/VariantAPI";
import { API_URL_IMAGE_PRODUCT } from "../../apis/URL/constants";
const EditQuantity = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);

  const [editedQuantities, setEditedQuantities] = useState({});

  const handleCartItemEdit = (variantId, editedQuantity) => {
    setEditedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: editedQuantity,
    }));
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    try {
      OrderAPI.get(id).then((orderApi) => {
        setItems(orderApi.items);
        console.log(orderApi.items);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const apiCall = async () => {
    setLoading(true);
    const formattedQuantities = Object.keys(editedQuantities).map((id) => ({
      id,
      quantity: editedQuantities[id],
    }));
    const items = {
      items: formattedQuantities,
    };
    console.log(items);
    try {
      await OrderAPI.updateQuantities(items, id).then((response) => {
        navigate("/orders/" + id);
      });
    } catch (error) {
      setShowError(true);
      //   setError(error.response.data.error);
      setError({ name: [error.response.data.error] });
      setLoading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="mt-20 relative">
          <ErrorAlert show={showError} error={error} />
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Edit items
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                {items.map((item) => (
                  <CartItem
                    key={item.variant.id}
                    imageUrl={
                      API_URL_IMAGE_PRODUCT + item.variant.images[0].name
                    }
                    productName={item.variant.product.name}
                    size={item.variant.size}
                    price={item.price}
                    discount={item.discount}
                    quantity={item.quantity}
                    sku={item.variant.sku}
                    color={item.variant.color.name}
                    showQuantity={true}
                    onEdit={handleCartItemEdit}
                    variantId={item.variant.id}
                  />
                ))}
              </div>

              <div className="sm:col-span-2 text-center">
                <button
                  type="submit"
                  onClick={apiCall}
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      ;
    </div>
  );
};

export default EditQuantity;
