import React, { useEffect, useState } from "react";
import OrderTableRow from "../../components/OrderTableRow";
import { OrderAPI } from "../../apis/OrderAPI";
import { OrderStatusEnum } from "../../utils/enum/OrderStatusEnum";
const Orders = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idProductDelete, setIdProductDelete] = useState(null);
  const [orders, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState([]);
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    per_page: null,
    total: null,
  });
  const [searchInput, setSearchInput] = useState(null);
  const [status, setStatus] = useState([{ id: 1, name: "All" }]);

  const [parameters, setParameter] = useState({
    status: null,
    search: null,
    page: 1,
  });
  const toggleSelect = (value) => {
    setIsSelectDisabled(value);
  };
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  useEffect(() => {
    const statusArray = [
      "All",
      ...Object.keys(OrderStatusEnum).map((key) => OrderStatusEnum[key]),
    ];
    setOrderStatus(statusArray);
    try {
      OrderAPI.getAll(parameters.page).then((orders) => {
        setOrder(orders.data);
        setPagination({
          current_page: orders.current_page,
          total: orders.total,
          last_page: orders.last_page,
          per_page: orders.per_page,
        });
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      OrderAPI.getAll(parameters.page, parameters).then((ordersApi) => {
        setOrder(ordersApi.data);
        setPagination({
          current_page: ordersApi.current_page,
          total: ordersApi.total,
          last_page: ordersApi.last_page,
          per_page: ordersApi.per_page,
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, [parameters]);

  const nextPage = () => {
    if (pagination.current_page < pagination.last_page) {
      setParameter({
        ...parameters,
        page: parameters.page + 1,
      });
    }
  };
  const previousPage = () => {
    if (pagination.current_page > 1) {
      setParameter({
        ...parameters,
        page: parameters.page - 1,
      });
    }
  };

  const openDeleteModal = (show, id) => {
    setShowModalDelete(show);
    setIdProductDelete(id);
  };

  const handleSearchButton = () => {
    setParameter({
      ...parameters,
      search: searchInput,
    });
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="mt-20 mb-10 md:ml-10 md:mr-10 relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex w-full mx-10 rounded bg-[#f3f4f6]">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                  <div className="col-span-1 md:col-span-4 ">
                    <div className="flex items-center w-full border rounded-md shadow-sm bg-white md:mb-2">
                      <input
                        className="w-full border-none bg-transparent px-4 py-1  outline-none focus:outline-none"
                        type="search"
                        name="search"
                        placeholder="Search..."
                        onChange={handleSearchInput}
                      />
                      <button
                        type="submit"
                        onClick={handleSearchButton}
                        className="m-2 rounded bg-blue-600 px-4 py-2 text-white"
                      >
                        <svg
                          className="fill-current h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Capa_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 56.966 56.966"
                          style={{ enableBackground: "new 0 0 56.966 56.966" }}
                          xmlSpace="preserve"
                          width="512px"
                          height="512px"
                        >
                          <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center bg-[#f3f4f6] ">
                <div className="flex w-full mx-10 rounded bg-[#f3f4f6] justify-end">
                  <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                    <div className="col-span-1  md:col-span-1 ">
                      <div className=" w-full lg:max-w-full ">
                        <select
                          onChange={(event) =>
                            setParameter({
                              ...parameters,
                              status: event.target.value,
                            })
                          }
                          className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                        >
                          {orderStatus.map((status, index) => (
                            <option key={index} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-[#111827] uppercase bg-[#ffffff]">
                <tr>
                  <th scope="col" className="p-4"></th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/12 "
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Order ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/6 "
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Customer
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/6 "
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Phone number
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/6"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Total
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/6"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/12"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Items
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 w-1/6"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 w-1/6"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {/* Largeur: 1/6 */}
                    Payment
                  </th>
                  <th scope="col" className="px-3 py-3 w-1/6">
                    {" "}
                    {/* Largeur: 1/6 */}
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <OrderTableRow
                    key={order.id}
                    order={order}
                    deleteModal={openDeleteModal}
                  />
                ))}
              </tbody>
            </table>

            {showModalDelete ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">Warning</h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModalDelete(false)}
                        >
                          <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                          Are you certain that you wish to delete this product ?{" "}
                          <br />
                        </p>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModalDelete(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          // onClick={deleteApiCall}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </div>

          <div className="flex justify-center mb-5">
            <nav>
              <ul className="flex">
                {pagination.last_page && pagination.current_page > 1 ? (
                  <li>
                    <a
                      className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
                      href="#"
                      aria-label="Previous"
                      onClick={previousPage}
                    >
                      <span className="material-icons text-sm">
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          height="1em"
                          width="1em"
                        >
                          <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                <li>
                  <a
                    className="mx-1 flex h-9 w-9 items-center justify-center rounded-full bg-gradient-to-tr from-blue-600 to-pink-400 p-0 text-sm text-white shadow-md shadow-pink-500/20 transition duration-150 ease-in-out"
                    href="#"
                  >
                    {parameters.page}
                  </a>
                </li>
                {pagination.last_page &&
                pagination.last_page > pagination.current_page ? (
                  <li>
                    <a
                      className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
                      href="#"
                      aria-label="Next"
                      onClick={nextPage}
                    >
                      <span className="material-icons text-sm">
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          height="1em"
                          width="1em"
                        >
                          <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
