import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const OrderAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `/api/admin/orders?page=${page}`;

    if (
      params.status !== undefined &&
      params.status !== null &&
      params.status !== "All"
    ) {
      url += `&status=${params.status}`;
    }
    if (params.search !== undefined && params.search !== null) {
      url += `&search=${params.search}`;
    }

    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  sendInvoice: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/orders/${id}/send_invoice`,
      method: "POST",
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/orders/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  update: async function (order, id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/orders/${id}`,
      method: "PUT",
      data: order,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  updateQuantities: async function (order, id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/orders/update_quantities/${id}`,
      method: "PUT",
      data: order,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(OrderAPI);
