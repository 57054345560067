import { HiInformationCircle } from "react-icons/hi";
import { Alert } from "flowbite-react";
import { useState } from "react";
import { useEffect } from "react";
function SuccessAlert({ show, message }) {
  if (!show) {
    return null;
  }

  return (
    <Alert
      color="success"
      icon={HiInformationCircle}
      className="absolute inset-x-0 mx-auto max-w-2xl "
    >
      <span className="font-medium"> </span> {message}
    </Alert>
  );
}
export default SuccessAlert;
