import { useState } from "react";
const CartItem = ({
  imageUrl,
  productName,
  size,
  price,
  discount,
  quantity,
  sku,
  color,
  showQuantity,
  onEdit,
  variantId,
}) => {
  const [editedQuantity, setEditedQuantity] = useState(quantity);

  const addQuantity = () => {
    setEditedQuantity(editedQuantity + 1);
    onEdit(variantId, editedQuantity + 1);
  };

  const removeQuantity = () => {
    if (editedQuantity > 0) {
      setEditedQuantity(editedQuantity - 1);
      onEdit(variantId, editedQuantity - 1);
    }
  };
  return (
    // <div className="justify-between mb-6 rounded-lg bg-white p-4 shadow-md sm:flex sm:justify-start">
    <div
      className={`justify-between mb-6 rounded-lg ${
        editedQuantity === 0 ? "bg-red-400" : "bg-white"
      } p-4 shadow-md sm:flex sm:justify-start`}
    >
      <img
        src={imageUrl}
        alt="product-image"
        className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center"
      />
      <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
        <div className="mt-5 sm:mt-0">
          <h2 className="text-lg font-bold text-gray-900">{productName}</h2>
          <p className="mt-1 text-xs text-gray-700">SKU : {sku}</p>
          <p className="mt-1 text-xs text-gray-700">size : {size}</p>
          <p className="mt-1 text-xs text-gray-700">color : {color}</p>
        </div>
        <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
          {showQuantity ? (
            <div className="flex items-center border-gray-100">
              <span
                onClick={removeQuantity}
                className="cursor-pointer select-none rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"
              >
                {" "}
                -{" "}
              </span>

              <span className="h-8 w-8 border select-none bg-white text-center text-xs outline-none flex items-center justify-center">
                {editedQuantity}
              </span>
              <span
                onClick={addQuantity}
                className="cursor-pointer rounded-r select-none  bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"
              >
                {" "}
                +{" "}
              </span>
            </div>
          ) : null}
          <div className="flex items-center space-x-4">
            {discount === null ? (
              <p className="text-sm">{price}$</p>
            ) : (
              <div>
                <p className="rounded-lg bg-red-50 text-red-500 text-sm">
                  - {discount}%
                </p>{" "}
                {(
                  parseFloat(price) -
                  parseFloat(price) * (parseFloat(discount) / 100)
                ).toFixed(2)}
                $
                <p className="text-sm text-gray-500">
                  <s>{price}$</s>
                </p>{" "}
              </div>
            )}
          </div>
          {!showQuantity ? (
            <div className="relative bottom-0 right-0 bg-green-500 text-white rounded-full w-8 h-8 flex justify-center items-center">
              <span>x {quantity} </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default CartItem;
