import React, { useState, useEffect } from "react";
import { TypeAPI } from "../../apis/TypeAPI";
import { CategoryAPI } from "../../apis/CategoryAPI";
import { useNavigate } from "react-router-dom";
import { DiscountAPI } from "../../apis/DiscountAPI";
import ErrorAlert from "../../components/ErrorAlert";
const AddType = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [type, setTypes] = useState({
    name: "",
    category_id: null,
    display: false,
  });
  const [categories, setCategory] = useState(null);
  const [discounts, setDiscount] = useState([
    { id: null, name: "none", value: null },
  ]);
  const [loading, setLoading] = useState(true);
  const handleInputType = (e) => {
    setTypes({ ...type, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    try {
      DiscountAPI.getAll().then((discountsApi) => {
        setDiscount((discounts) => [...discounts, ...discountsApi]);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }

    try {
      CategoryAPI.getAll().then((categoriesApi) => {
        setCategory(categoriesApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const apiCall = async () => {
    if (type.discount_id === "none") {
      type.discount_id = null;
    }
    if (type.category_id == null && categories.length > 0) {
      type.category_id = categories[0].id;
    }
    try {
      await TypeAPI.create(type).then((response) => {
        setTypes({ name: "" });
        navigate("/types");
      });
    } catch (error) {
      setShowError(true);
      setError(error.response.data.error);
      // console.log(error.response.data.message);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="mt-20 relative">
          <ErrorAlert show={showError} error={error} />
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Add new type
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Type Name
                </label>
                <input
                  type="text"
                  value={type.name}
                  name="name"
                  id="name"
                  onChange={handleInputType}
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Input type name"
                  required
                />
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Select Discount
                </label>
                <select
                  onChange={(event) =>
                    setTypes({
                      ...type,
                      discount_id: event.target.value,
                    })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {discounts &&
                    discounts.length > 0 &&
                    discounts.map((discount) => (
                      <option key={discount.id} value={discount.id}>
                        {discount.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Select Category
                </label>
                <select
                  onChange={(event) =>
                    setTypes({
                      ...type,
                      category_id: event.target.value,
                    })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {categories &&
                    categories.length > 0 &&
                    categories.map((categories) => (
                      <option key={categories.id} value={categories.id}>
                        {categories.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="w-full">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Display
                </label>
                <label className="relative mt-2 inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={type.display}
                    onChange={() =>
                      setTypes({ ...type, display: !type.display })
                    }
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>{" "}
              </div>

              <div className="w-full sm:col-span-2"></div>

              <div className="text-center">
                <button
                  type="submit"
                  onClick={apiCall}
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Add type
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      ;
    </div>
  );
};

export default AddType;
