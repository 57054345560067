import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const AuthAPI = {
  csrfToken: async function (cancel = false) {
    const response = await api.request({
      url: `/sanctum/csrf-cookie`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.csrfToken.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },

  login: async function (email, password, cancel = false) {
    const response = await api.request({
      url: `/api/auth/admin/login`,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
      signal: cancel
        ? cancelApiObject[this.login.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  logout: async function (cancel = false) {
    const response = await api.request({
      url: `/api/auth/admin/logout`,
      method: "POST",
      signal: cancel
        ? cancelApiObject[this.logout.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  forgotPassword: async function (email, cancel = false) {
    const response = await api.request({
      url: `/api/auth/admin/forgot_password`,
      method: "POST",
      data: {
        email: email,
      },
      signal: cancel
        ? cancelApiObject[this.forgotPassword.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
  changePassword: async function (credentials, cancel = false) {
    const response = await api.request({
      url: `/api/auth/admin/change_password`,
      method: "POST",
      data: credentials,
      signal: cancel
        ? cancelApiObject[this.changePassword.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(AuthAPI);
