import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import imageNotFound from "../assets/images/nope-not-here.webp";
import { API_URL_IMAGE_PRODUCT } from "../apis/URL/constants";
const VariantTable = ({ variant, deleteModal }) => {
  const imageArray = new Array(4).fill(null);
  const champDeTexteRef = useRef(null);
  variant.images.forEach((image) => {
    imageArray[image.tag - 1] = API_URL_IMAGE_PRODUCT + image.name;
  });
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i] === null) {
      imageArray[i] = imageNotFound;
    }
  }

  return (
    <div className="mb-10">
      <div className="flex justify-between flex-wrap sm:flex-nowrap">
        <div className="mt-2 mb-8 w-full sm:w-1/2">
          <div
            className="w-16 h-8 ml-4"
            style={{ backgroundColor: "#" + variant.color }}
          ></div>
        </div>
        <div className="mt-2 mb-8 w-full sm:w-1/2 flex justify-end">
          <img
            className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-24 w-24 rounded-2xl object-fill object-center"
            alt="Image placeholder"
            src={imageArray[0]}
          />
          <img
            className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-24 w-24 rounded-2xl object-fill object-center"
            alt="Image placeholder"
            src={imageArray[1]}
          />
          <img
            className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-24 w-24 rounded-2xl object-fill object-center"
            alt="Image placeholder"
            src={imageArray[2]}
          />
          <img
            className="relative inline-block h-24 w-24 rounded-2xl object-fill object-center"
            alt="Image placeholder"
            src={imageArray[3]}
          />
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-[#111827] uppercase bg-[#ffffff] ">
          <tr>
            <th scope="col" className="p-4"></th>
            <th
              scope="col"
              className="px-3 py-3"
              // onClick={trierParNom}
              style={{ cursor: "pointer" }}
            >
              SKU
            </th>
            <th
              scope="col"
              className="px-3 py-3"
              // onClick={trierParDiscount}
              style={{ cursor: "pointer" }}
            >
              Barcode
            </th>
            <th
              scope="col"
              className="px-3 py-3"
              // onClick={trierParBool}
              style={{ cursor: "pointer" }}
            >
              Size
            </th>
            <th
              scope="col"
              className="px-3 py-3"
              // onClick={trierParDiscount}
              style={{ cursor: "pointer" }}
            >
              Quantity
            </th>
            <th scope="col" className="px-3 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {variant.sizes.map((variant) => (
            <tr className="bg-[#ffffff] border-b  hover:bg-[#f3f4f6] ">
              <td className="">
                <div className="flex items-center ml-3"></div>
              </td>
              <td
                scope="row"
                className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
              >
                {variant.sku}
              </td>
              <td>
                <th
                  scope="row"
                  className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
                >
                  {variant.barcode}
                </th>
              </td>
              <td>
                <th
                  scope="row"
                  className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
                >
                  {variant.size}
                </th>
              </td>
              <td>
                <th
                  scope="row"
                  className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
                >
                  {variant.quantity}
                </th>
              </td>

              <td className="flex px-3 py-3">
                <Link to={"/variants/edit_variant/" + variant.id}>
                  <svg
                    className="h-6 w-6 cursor-pointer"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <svg
                  onClick={() => {
                    deleteModal(true, variant.id);
                  }}
                  className="h-6 w-6 ml-4 cursor-pointer"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  // onClick={copyId}
                  onClick={() => navigator.clipboard.writeText(`${variant.id}`)}
                  className="h-6 w-6 ml-4 cursor-pointer"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  height="20px"
                  width="20px"
                >
                  <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z" />
                </svg>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VariantTable;
