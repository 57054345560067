import React, { useEffect, useState } from "react";
import VariantTable from "../../components/VariantTable";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { ItemAPI } from "../../apis/ItemAPI";
import { useNavigate, useParams } from "react-router-dom";
import { VariantAPI } from "../../apis/VariantAPI";
const Items = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idVariantDelete, setIdVariantDelete] = useState(null);

  useEffect(() => {
    try {
      ItemAPI.get(id).then((itemApi) => {
        setItem(itemApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const removeSizeById = (idToRemove) => {
    const updatedVariants = item.variants.map((variant) => {
      const updatedSizes = variant.sizes.filter(
        (size) => size.id !== idToRemove
      );
      return {
        ...variant,
        sizes: updatedSizes,
      };
    });

    const filteredVariants = updatedVariants.filter(
      (variant) => variant.sizes.length > 0
    );

    setItem((prevProduct) => ({
      ...prevProduct,
      variants: filteredVariants,
    }));
  };

  const deleteApiCall = () => {
    try {
      VariantAPI.delete(idVariantDelete).then(() => {
        removeSizeById(idVariantDelete);
        setShowModalDelete(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const openDeleteModal = (show, id) => {
    setShowModalDelete(show);
    setIdVariantDelete(id);
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          {/* <div className="mt-10 mb-10 md:ml-10 md:mr-10 relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div className="flex flex-col justify-center items-center ">
              <div className="relative flex flex-col items-center rounded-[20px]  max-w-[95%] w-full  bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3">
                <div className="mt-2 md:justify-between items-center mb-8 w-full">
                  <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                    {item.name}
                  </h4>
                  <div className="flex justify-center md:justify-end mb-5">
                    <Link to="add_category">
                      <Button className="bg-green-400">+ ADD Category</Button>
                    </Link>
                  </div>
                </div> */}
          <div className="mt-10 mb-10 md:ml-10 md:mr-10 relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="flex flex-col justify-center items-center">
              <div className="relative flex flex-col items-center rounded-[20px] max-w-[95%] w-full bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3">
                <div className="flex justify-between items-center w-full mt-2  mb-8">
                  <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                    {item.name}
                  </h4>
                  <div className="flex justify-center md:justify-end">
                    <Link to={"/variants/add_variant/" + id}>
                      <Button className="bg-green-400">+ ADD variant</Button>
                    </Link>
                  </div>
                </div>
                <div className="grid  grid-cols-1 md:grid-cols-4 gap-4 px-2 w-full">
                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Price</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {"$" + item.price}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Category</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.category}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Type</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.type}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Collection</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.collection}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Description</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.description !== null
                        ? item.description.length > 20
                          ? item.description.substring(0, 30) + "..."
                          : item.description
                        : " "}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Displayed</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.display ? "yes" : "no"}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Out Of Stock</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.outOfStock ? "yes" : "no"}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p className="text-sm text-gray-600">Discount</p>
                    <p className="text-base font-medium text-navy-700 dark:text-white">
                      {item.discount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 mb-10 md:ml-10 md:mr-10 relative shadow-md sm:rounded-lg ">
            {/* <h1 className="text-3xl font-bold underline">Hello world!</h1> */}
            {item.variants.map((variant) => (
              <VariantTable
                key={variant.id}
                variant={variant}
                deleteModal={openDeleteModal}
              />
            ))}
          </div>
          {showModalDelete ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">Warning</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModalDelete(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Are you certain that you wish to delete this variant ?{" "}
                        <br />
                      </p>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModalDelete(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={deleteApiCall}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      )}
      ;
    </div>
  );
};

export default Items;
