import React, { useState } from "react";
import deleteIcon from "../assets/logo/delete-svgrepo-com.svg";
const VariantInputList = ({ index, onAddVariant }) => {
  const [inputs, setInputs] = useState({
    sku: "",
    barcode: "",
    size: "",
    quantity: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
    onAddVariant(index + 1, name, value);
  };

  const handleChangeInputNumber = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]+([.][0-9]+)?$/.test(inputValue)) {
      const { name, value } = e.target;
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
      onAddVariant(index + 1, name, value);
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="px-4 py-8 lg:py-5 mt-10 mb-2 md:ml-10 md:mr-10 relative shadow-md sm:rounded-lg sm:w-1/2 grid grid-cols-1 sm:grid-cols-4 gap-4">
          <div className="flex flex-col">
            <input
              id="input1"
              type="text"
              className="px-4 py-2 border rounded-md"
              name="sku"
              value={inputs.sku}
              onChange={handleInputChange}
              placeholder="SKU"
            />
          </div>
          <div className="flex flex-col">
            <input
              id="input2"
              type="text"
              className="px-4 py-2 border rounded-md"
              name="barcode"
              value={inputs.barcode}
              onChange={handleInputChange}
              placeholder="Barcode"
            />
          </div>
          <div className="flex flex-col">
            <input
              id="input3"
              type="text"
              className="px-4 py-2 border rounded-md"
              name="size"
              value={inputs.size}
              onChange={handleInputChange}
              placeholder="Size"
            />
          </div>
          <div className="flex flex-col">
            <input
              id="input4"
              type="number"
              className="px-4 py-2 border rounded-md"
              name="quantity"
              step="1"
              inputMode="decimal"
              pattern="[0-9]+([.][0-9]+)?"
              value={inputs.quantity}
              onChange={handleChangeInputNumber}
              placeholder="Quantity"
            />
          </div>
        </div>
        {/* <div className="lg:py-5 mt-10 mb-2 relative">
          <img
            src={deleteIcon}
            className="w-10 h-10 text-gray-500"
            onClick={onDeleteVariant}
          />
        </div> */}
      </div>
    </div>
  );
};
export default VariantInputList;
