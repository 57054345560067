import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const UpdateStockAPI = {
  update: async function (data, cancel = false) {
    const response = await api.request({
      url: `/api/admin/import/variants/quantity`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};
const cancelApiObject = defineCancelApiObject(UpdateStockAPI);
