import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TypeAPI } from "../apis/TypeAPI";
const TypeTableRow = ({ type, deleteModal }) => {
  const [isChecked, setIsChecked] = useState(type.display);

  const updateApiCall = () => {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("display", !isChecked);
    try {
      TypeAPI.update(formData, type.id).then(() => {
        setIsChecked(!isChecked);
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <tr className="bg-[#ffffff] border-b  hover:bg-[#f3f4f6] ">
      <td className="">
        <div className="flex items-center ml-3"></div>
      </td>
      <th
        scope="row"
        className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
      >
        {type.name}
      </th>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {type.category}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {type.discount}
        </th>
      </td>
      <td className="px-3 py-3">
        <label className="relative inline-flex items-center mb-4 cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            checked={isChecked}
            onChange={updateApiCall}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      </td>

      <td className="flex px-3 py-3">
        <Link to={"/types/edit_type/" + type.id}>
          <svg
            className="h-6 w-6 mr-4 cursor-pointer"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
        <svg
          onClick={() => {
            deleteModal(true, type.id);
          }}
          className="h-6 w-6 ml-4 cursor-pointer"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </td>
    </tr>
  );
};

export default TypeTableRow;
