import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderAPI } from "../../apis/OrderAPI";
import { API_URL_IMAGE_PRODUCT } from "../../apis/URL/constants";
import { Link } from "react-router-dom";
import { Button } from "flowbite-react";
import moment from "moment";
import SuccessAlert from "../../components/SuccessAlert";
import { OrderStatusEnum } from "../../utils/enum/OrderStatusEnum";
import ErrorAlert from "../../components/ErrorAlert";
import CartItem from "../../components/CartItem";
const OrderDetails = () => {
  const { id } = useParams();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [order, setOrder] = useState([]);
  const [paid, setPaid] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [message, setMessage] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const handleSelectChange = (event) => {
    const value = event.target.value === "paid";
    setPaid(value);
    updateApiCall();
  };
  const handleCancelOrder = () => {
    setShowModalDelete(false);
    updateStatusApiCall(selectedStatus);
  };
  const handleStatusChange = (event) => {
    const value = event.target.value;
    if (event.target.value === "Cancelled") {
      setSelectedStatus(value);
      setShowModalDelete(true);
    } else {
      updateStatusApiCall(value);
    }
  };
  useEffect(() => {
    const statusArray = [
      ...Object.keys(OrderStatusEnum).map((key) => OrderStatusEnum[key]),
    ];
    setOrderStatus(statusArray);
    try {
      OrderAPI.get(id).then((orderApi) => {
        orderApi.date = moment(orderApi.created_at).format(
          "Do MMMM YYYY [at] hh:mm A"
        );
        setOrder(orderApi);
        setPaid(orderApi.paid);
        console.log(orderApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let timeoutId;
    if (showAlert) {
      timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showAlert]);

  useEffect(() => {
    let timeoutId;
    if (showError) {
      timeoutId = setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showError]);
  const sendInvoiceMail = async () => {
    try {
      await OrderAPI.sendInvoice(id).then(() => {
        setMessage("The email has been successfully sent");
        setShowAlert(true);
      });
    } catch (error) {
      console.log(error.response.data);
      setError({ name: [error.response.data.error] });
      //   setError(error.response.data);
      setShowError(true);
    }
  };
  const updateStatusApiCall = async (newStatus) => {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("status", newStatus);
    try {
      await OrderAPI.update(formData, order.id).then(() => {
        setOrder({
          ...order,
          status: newStatus,
        });
        setMessage("Order status has been changed to : " + newStatus);
        setShowAlert(true);
      });
    } catch (error) {
      console.log(error.response.data);
      setError({ name: [error.response.data.error] });
      //   setError(error.response.data);
      setShowError(true);
    }
  };

  const updateApiCall = () => {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("paid", !paid);
    try {
      OrderAPI.update(formData, order.id).then(() => {
        if (paid === true) {
          setMessage("Order has been marked as unpaid");
        } else {
          setMessage("Order has been marked as paid");
        }

        setShowAlert(true);
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <SuccessAlert show={showAlert} message={message} />
          <ErrorAlert show={showError} error={error} />
          <div className="flex justify-start items-start space-y-2 flex-col mt-12 px-12">
            <h1 className="text-3xl dark:text-white lg:text-3xl font-semibold leading-7 lg:leading-9 text-gray-800">
              Order #{order.id}
            </h1>
            <p className="text-base dark:text-gray-300 font-medium leading-6 text-gray-600">
              {order.date}
            </p>
          </div>
          <div className="flex mt-5 md:px-10 md:py-10 flex-col md:flex-row">
            {/* Première colonne, environ 3/4 de la page */}
            <div className="w-full md:w-1/4 ">
              {" "}
              <div class="bg-white  overflow-hidden shadow rounded-lg border w-full">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Order details
                  </h3>
                  {/* <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    This is some information about the user.
                  </p> */}
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Status</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {/* {order.status} */}
                        <select
                          value={order.status}
                          onChange={handleStatusChange}
                          className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                        >
                          {orderStatus &&
                            orderStatus.length > 0 &&
                            orderStatus.map((orderStatus) => (
                              <option key={orderStatus} value={orderStatus}>
                                {orderStatus}
                              </option>
                            ))}
                        </select>
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Payment</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <select
                          className={`p-2 border rounded-md ${
                            paid ? "bg-green-500" : "bg-red-500"
                          }`}
                          value={paid ? "paid" : "unpaid"}
                          onChange={handleSelectChange}
                        >
                          <option className="bg-green-500" value="paid">
                            Paid
                          </option>
                          <option className="bg-red-500" value="unpaid">
                            Unpaid
                          </option>
                        </select>
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Send Invoice
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <Button
                          className="px-4 bg-blue-400"
                          onClick={sendInvoiceMail}
                        >
                          SEND
                        </Button>
                      </dd>
                    </div>
                    {order.status !== "Cancelled" &&
                    order.status !== "Waiting confirmation" ? (
                      <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Quantity
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <Link to={"/orders/edit_item_quantity/" + order.id}>
                            <Button className="px-4 bg-orange-400">
                              Edit Quantity
                            </Button>
                          </Link>
                        </dd>
                      </div>
                    ) : null}
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Note from customer
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.note === null ? (
                          <div>
                            <p>The customer did not provide a comment</p>
                          </div>
                        ) : (
                          <div> {order.note}</div>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/4 ">
              <div className=" px-4">
                <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                  <div className="rounded-lg md:w-full">
                    {order.items.map((item) => (
                      <CartItem
                        key={item.variant.id}
                        imageUrl={
                          API_URL_IMAGE_PRODUCT + item.variant.images[0].name
                        }
                        productName={item.variant.product.name}
                        size={item.variant.size}
                        price={item.price}
                        discount={item.discount}
                        quantity={item.quantity}
                        sku={item.variant.sku}
                        color={item.variant.color.name}
                      />
                    ))}
                    {order.status !== "Cancelled" &&
                    order.status !== "Waiting confirmation" ? (
                      <div className="flex mt-8 mb-8 items-center justify-center w-full">
                        <div className="w-full border-t border-gray-400 relative">
                          <Link to={"/orders/add_item/" + order.id}>
                            <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-2 border border-gray-400">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                              </svg>
                            </button>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="mt-6  rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-3/3">
                      <div className="mb-2 flex justify-between">
                        <p className="text-gray-700">Subtotal</p>
                        <p className="text-gray-700">
                          {" "}
                          {"$" +
                            parseFloat(
                              (parseFloat(order.total_amount) * 0.89).toFixed(2)
                            )}
                        </p>
                      </div>
                      <div className="mb-2 flex justify-between">
                        <p className="text-gray-700">VAT (11%)</p>
                        <p className="text-gray-700">
                          {"$" +
                            parseFloat(
                              (parseFloat(order.total_amount) * 0.11).toFixed(2)
                            )}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-gray-700">Shipping</p>
                        <p className="text-gray-700">${order.shipping_cost}</p>
                      </div>
                      <hr className="my-4" />
                      <div className="flex justify-between">
                        <p className="text-lg font-bold">Total</p>
                        <div>
                          <p className="mb-1 text-lg font-bold">
                            {"$" +
                              parseFloat(
                                parseFloat(order.total_amount) +
                                  parseFloat(order.shipping_cost)
                              ).toFixed(2)}{" "}
                            USD
                          </p>
                          {/* <p className="text-sm text-gray-700">including VAT</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/4 ">
              <div class="bg-white overflow-hidden shadow rounded-lg border w-full">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Shipping details
                  </h3>
                  {/* <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    This is some information about the user.
                  </p> */}
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        First name
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.first_name}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Last name
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.last_name}
                      </dd>
                    </div>

                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Phone number
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.phone_number}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.email}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Country</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.country}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">State</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.state}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">City</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.city}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Address</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.shipping_information.address}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div class="bg-white mt-2 overflow-hidden shadow rounded-lg border w-full">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Billing details
                  </h3>
                  {/* <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    This is some information about the user.
                  </p> */}
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        First name
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.first_name}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Last name
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.last_name}
                      </dd>
                    </div>

                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Phone number
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.phone_number}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.email}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Country</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.country}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">State</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.state}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">City</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.city}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Address</dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {order.billing_information.address}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          {showModalDelete ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">Warning</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModalDelete(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Are you sure you want to cancel this order? Once
                        cancelled, you won't be able to change its status
                        <br />
                      </p>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModalDelete(false)}
                      >
                        Keep Order
                      </button>
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleCancelOrder}
                      >
                        Cancel Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default OrderDetails;
