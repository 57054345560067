import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryAPI } from "../../apis/CategoryAPI";
import { Navigate } from "react-router-dom";
import { DiscountAPI } from "../../apis/DiscountAPI";
const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    name: "",
    display: false,
    discount_id: null,
  });
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscount] = useState([
    { id: null, name: "none", value: null },
  ]);

  const handleInputCategory = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  const handleChangeDiscount = (newDiscountId) => {
    setCategory({
      ...category,
      discount_id: newDiscountId,
    });
  };

  useEffect(() => {
    try {
      DiscountAPI.getAll().then((discountsApi) => {
        setDiscount((discounts) => [...discounts, ...discountsApi]);
      });
    } catch (error) {
      console.log(error);
    }

    try {
      CategoryAPI.get(id).then((category) => {
        setCategory({
          name: category.name,
          display: category.display,
          discount_id: category.discount_id,
        });
        setLoading(false);
      });
    } catch (error) {
      console.log(error.response.data);
    }
  }, []);

  useEffect(() => {
    handleChangeDiscount(category.discount_id);
  }, [category.discount_id]);

  const apiCall = async () => {
    if (category.discount_id === "none") {
      category.discount_id = null;
    }

    try {
      await CategoryAPI.update(category, id).then(() => {
        navigate("/categories");
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      ) : (
        <section className="mt-20 relative">
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Edit Category
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Category Name
                </label>
                <input
                  type="text"
                  value={category.name}
                  name="name"
                  id="name"
                  onChange={handleInputCategory}
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Type category name"
                  required
                />
              </div>
              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Select Discount
                </label>
                {/* <select
                  onChange={(event) =>
                    setCategory({
                      ...category,
                      discount_id: event.target.value,
                    })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {discounts &&
                    discounts.length > 0 &&
                    discounts.map((discount) => (
                      <option key={discount.id} value={discount.id}>
                        {discount.name}
                      </option>
                    ))}
                </select> */}
                <select
                  onChange={(event) => handleChangeDiscount(event.target.value)}
                  value={category.discount_id}
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {discounts &&
                    discounts.length > 0 &&
                    discounts.map((discount) => (
                      <option key={discount.id} value={discount.id}>
                        {discount.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="text-center">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Edit category
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default EditCategory;
