export const OrderStatusEnum = {
  WaitingConfirmation: "Waiting confirmation",
  Unfulfilled: "Unfulfilled",
  ReadyToPickUp: "Ready to pick up",
  PickedUp: "Picked up",
  Delivered: "Delivered",
  OnHold: "On Hold",
  Refunded: "Refunded",
  Cancelled: "Cancelled",
  Completed: "Completed",
};
