import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const VariantAPI = {
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: "/api/admin/categories",
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (variant, cancel = false) {
    const response = await api.request({
      url: `/api/admin/variants`,
      method: "POST",
      data: variant,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  multipleInsertion: async function (variants, cancel = false) {
    const response = await api.request({
      url: `/api/admin/variants/multiple`,
      method: "POST",
      data: variants,
      headers: { "Content-Type": "multipart/form-data" },
      signal: cancel
        ? cancelApiObject[
            this.multipleInsertion.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/variants/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  deleteImage: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/image/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.deleteImage.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/variants/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  update: async function (variant, id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/variants/${id}`,
      method: "POST",
      data: variant,
      headers: { "Content-Type": "multipart/form-data" },
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(VariantAPI);
