import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const TypeAPI = {
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: "/api/admin/types",
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (type, cancel = false) {
    const response = await api.request({
      url: `/api/admin/types`,
      method: "POST",
      data: type,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/types/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/types/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  update: async function (type, id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/types/${id}`,
      method: "PUT",
      data: type,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getByCategory: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/categories/${id}/types`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getByCategory.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(TypeAPI);
