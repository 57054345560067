import React, { useEffect, useState } from "react";
import ProductTableRow from "../../components/ProductTableRow";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { CategoryAPI } from "../../apis/CategoryAPI";
import { ProductAPI } from "../../apis/ProductAPI";
import { CollectionAPI } from "../../apis/CollectionAPI";
import { TypeAPI } from "../../apis/TypeAPI";
const Products = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idProductDelete, setIdProductDelete] = useState(null);
  const [products, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSelectDisabled, setIsSelectDisabled] = useState(true);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    per_page: null,
    total: null,
  });
  const [searchInput, setSearchInput] = useState(null);
  const [categories, setCategory] = useState([
    { id: 1, name: "Select Category" },
  ]);
  const [types, setType] = useState([{ id: 1, name: "Select Type" }]);
  const [collections, setCollection] = useState([
    {
      id: 1,
      name: "Select Collection",
    },
  ]);

  const [parameters, setParameter] = useState({
    categoryId: null,
    typeId: null,
    collectionId: null,
    search: null,
    page: 1,
  });
  const toggleSelect = (value) => {
    setIsSelectDisabled(value);
  };
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  useEffect(() => {
    try {
      CategoryAPI.getAll().then((categoriesApi) => {
        setCategory((categories) => [...categories, ...categoriesApi]);
      });
    } catch (error) {
      console.log(error);
    }

    try {
      CollectionAPI.getAll().then((collectionsApi) => {
        setCollection((collections) => [...collections, ...collectionsApi]);
        console.log(collections);
      });
    } catch (error) {
      console.log(error);
    }

    try {
      ProductAPI.getAll(parameters.page).then((products) => {
        setProduct(products.data);
        setPagination({
          current_page: products.current_page,
          total: products.total,
          last_page: products.last_page,
          per_page: products.per_page,
        });
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      ProductAPI.getAll(parameters.page, parameters).then((productsApi) => {
        setProduct(productsApi.data);
        setPagination({
          current_page: productsApi.current_page,
          total: productsApi.total,
          last_page: productsApi.last_page,
          per_page: productsApi.per_page,
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, [parameters]);

  const nextPage = () => {
    if (pagination.current_page < pagination.last_page) {
      setParameter({
        ...parameters,
        page: parameters.page + 1,
      });
    }
  };
  const previousPage = () => {
    if (pagination.current_page > 1) {
      setParameter({
        ...parameters,
        page: parameters.page - 1,
      });
    }
  };

  useEffect(() => {
    if (parameters.categoryId !== "1" && parameters.categoryId !== null) {
      toggleSelect(false);
      try {
        TypeAPI.getByCategory(parameters.categoryId).then((typesApi) => {
          setType([{ id: 1, name: "Select Type" }]);
          setType((types) => [...types, ...typesApi.data]);
          setParameter({
            ...parameters,
            typeId: "1",
          });
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      toggleSelect(true);
      setType([{ id: 1, name: "Select Type" }]);
      // setParameter({
      //   ...parameters,
      //   categoryId: null,
      // });
    }
  }, [parameters.categoryId]);

  const openDeleteModal = (show, id) => {
    setShowModalDelete(show);
    setIdProductDelete(id);
  };

  const [tri, setTri] = useState({
    colonne: null,
    ascendant: true,
  });

  const trierParNom = () => {
    const nouvellesDonnees = trier("name");
    setProduct(nouvellesDonnees);
  };

  const trierParBool = () => {
    const nouvellesDonnees = trier("display");
    setProduct(nouvellesDonnees);
  };
  const trierParDiscount = () => {
    const nouvellesDonnees = trier("discount");
    setProduct(nouvellesDonnees);
  };

  const trier = (colonne) => {
    if (!products) return;

    const ascendant = tri.colonne === colonne ? !tri.ascendant : true;

    const nouvellesDonnees = [...products].sort((a, b) => {
      if (colonne === "display") {
        return ascendant ? a[colonne] - b[colonne] : b[colonne] - a[colonne];
      } else if (colonne === "value") {
        return ascendant ? a[colonne] - b[colonne] : b[colonne] - a[colonne];
      } else {
        if (a[colonne] === null || b[colonne] === null) {
          if (a[colonne] === null && b[colonne] === null) return 0;
          if (a[colonne] === null) return ascendant ? 1 : -1;
          if (b[colonne] === null) return ascendant ? -1 : 1;
        }
        const compareValue = a[colonne].localeCompare(b[colonne], undefined, {
          sensitivity: "base",
        });
        return ascendant ? compareValue : -compareValue;
      }
    });

    setTri({ colonne, ascendant });
    return nouvellesDonnees;
  };
  const handleSearchButton = () => {
    setParameter({
      ...parameters,
      search: searchInput,
    });
  };

  const deleteApiCall = () => {
    try {
      ProductAPI.delete(idProductDelete).then(() => {
        const updatedData = products.filter(
          (item) => item.id !== idProductDelete
        );
        setProduct(updatedData);
        setShowModalDelete(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="mt-20 mb-10 md:ml-10 md:mr-10 relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div className="flex justify-center md:justify-end mb-5">
              <Link to="update_stock">
                <Button className="bg-green-400 mr-10">Update Stock</Button>
              </Link>

              <Link to="add_product">
                <Button className="bg-green-400">ADD Product</Button>
              </Link>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex w-full mx-10 rounded bg-[#f3f4f6]">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                  <div className="col-span-1 md:col-span-4 ">
                    <div className="flex items-center w-full border rounded-md shadow-sm bg-white mb-2">
                      <input
                        className="w-full border-none bg-transparent px-4 py-1  outline-none focus:outline-none"
                        type="search"
                        name="search"
                        placeholder="Search..."
                        onChange={handleSearchInput}
                      />
                      <button
                        type="submit"
                        onClick={handleSearchButton}
                        className="m-2 rounded bg-blue-600 px-4 py-2 text-white"
                      >
                        <svg
                          className="fill-current h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          id="Capa_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 56.966 56.966"
                          style={{ enableBackground: "new 0 0 56.966 56.966" }}
                          xmlSpace="preserve"
                          width="512px"
                          height="512px"
                        >
                          <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center bg-[#f3f4f6]">
                <div className="flex w-full mx-10 rounded bg-[#f3f4f6] ">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="col-span-1  md:col-span-1">
                      <div className=" w-full lg:max-w-full">
                        <select
                          onChange={(event) =>
                            setParameter({
                              ...parameters,
                              collectionId: event.target.value,
                            })
                          }
                          className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                        >
                          {collections &&
                            collections.length > 0 &&
                            collections.map((collection) => (
                              <option key={collection.id} value={collection.id}>
                                {collection.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-span-1  md:col-span-1">
                      <div className="w-full lg:max-w-full">
                        <select
                          onChange={(event) =>
                            setParameter({
                              ...parameters,
                              categoryId: event.target.value,
                            })
                          }
                          className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                        >
                          {categories &&
                            categories.length > 0 &&
                            categories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-span-1  md:col-span-1">
                      <div className=" w-full lg:max-w-full">
                        <select
                          onChange={(event) =>
                            setParameter({
                              ...parameters,
                              typeId: event.target.value,
                            })
                          }
                          disabled={isSelectDisabled}
                          className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                        >
                          {types &&
                            types.length > 0 &&
                            types.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-[#111827] uppercase bg-[#ffffff] ">
                <tr>
                  <th scope="col" className="p-4"></th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParNom}
                    style={{ cursor: "pointer" }}
                  >
                    Product name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParDiscount}
                    style={{ cursor: "pointer" }}
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParDiscount}
                    style={{ cursor: "pointer" }}
                  >
                    Discount
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParNom}
                    style={{ cursor: "pointer" }}
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParNom}
                    style={{ cursor: "pointer" }}
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParNom}
                    style={{ cursor: "pointer" }}
                  >
                    Collection
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3"
                    onClick={trierParBool}
                    style={{ cursor: "pointer" }}
                  >
                    Display
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <ProductTableRow
                    key={product.id}
                    product={product}
                    deleteModal={openDeleteModal}
                  />
                ))}
              </tbody>
            </table>

            {showModalDelete ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">Warning</h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModalDelete(false)}
                        >
                          <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                          Are you certain that you wish to delete this product ?{" "}
                          <br />
                        </p>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModalDelete(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={deleteApiCall}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </div>

          <div className="flex justify-center mb-5">
            <nav>
              <ul className="flex">
                {pagination.last_page && pagination.current_page > 1 ? (
                  <li>
                    <a
                      className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
                      href="#"
                      aria-label="Previous"
                      onClick={previousPage}
                    >
                      <span className="material-icons text-sm">
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          height="1em"
                          width="1em"
                        >
                          <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                <li>
                  <a
                    className="mx-1 flex h-9 w-9 items-center justify-center rounded-full bg-gradient-to-tr from-blue-600 to-pink-400 p-0 text-sm text-white shadow-md shadow-pink-500/20 transition duration-150 ease-in-out"
                    href="#"
                  >
                    {parameters.page}
                  </a>
                </li>
                {pagination.last_page &&
                pagination.last_page > pagination.current_page ? (
                  <li>
                    <a
                      className="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
                      href="#"
                      aria-label="Next"
                      onClick={nextPage}
                    >
                      <span className="material-icons text-sm">
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          height="1em"
                          width="1em"
                        >
                          <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z" />
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
